import React from 'react';
import PropTypes from 'prop-types';

import styles from './loader.module.scss';

const LoaderSpinner = React.memo(({ children, alignPageCenter, justifyCenter, small }) => {
	return (
		<div className={`${styles.default} ${alignPageCenter ? styles.alignPageCenter : ''} ${justifyCenter ? styles.justifyCenter : ''}`}>
			<img src="/loader-spinner.svg" style={{ width: small ? 30 : 80, height: small ? 30 : 80 }} title="Loading ..." alt="Loading content ..." />
			{children && <p>{children}</p>}
		</div>
	);
});

LoaderSpinner.defaultProps = {
	children: null,
	alignPageCenter: false,
	justifyCenter: true,
	small: false
};

LoaderSpinner.propTypes = {
	children: PropTypes.node,
	alignPageCenter: PropTypes.bool,
	justifyCenter: PropTypes.bool,
	small: PropTypes.bool
};

export default LoaderSpinner;
