import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useInView } from 'react-intersection-observer';

import styles from './item-list.module.scss';
import ItemListBox from '../item-list-box/item-list-box';
import Carousel from '../carousel/carousel';
import LoaderSpinner from '../loader/loader-spinner';
import NoResult from '../list-no-result/no-result';
import useLanguage from '../_hooks/useLanguage';
import useAuction from '../_hooks/useAuction';

const ItemList = React.memo(({ name, title, link, noContainer, colClass, filters, carousel, options, colOpt, onQueryEnd, itemProps, append, skipped, noResult, page, perPage, orderBy, loader }) => {
	const { t } = useLanguage();

	const { ref, inView } = useInView({
		threshold: 0,
		triggerOnce: true
	});

	const [list, setList] = useState(null);
	const [carouselProps, setCarouselProps] = useState({});

	const { loading, get, result, error, setVariables } = useAuction(`auction>items|${name}`, { refetch_vars_not_empty: true });

	useEffect(() => {
		if (inView) {
			setVariables({
				auction_status_id: 20,
				page,
				per_page: perPage,
				order_by: orderBy,
				...filters
			});
		}
	}, [inView]);

	useEffect(() => {
		if (result) {
			setList(result.list || []);
			if (onQueryEnd) onQueryEnd(result.list, null);
		}

		if (error && onQueryEnd) onQueryEnd(null, error);
	}, [result, error]);

	useEffect(() => {
		// Prepare carousel props
		if (Object.keys(options).length > 0) {
			setCarouselProps({ options });
		}
	}, []);

	// Handle skipped items
	const [skip, setSkip] = useState(skipped);

	const addToListSkipped = (id) => {
		setSkip([...skip, id]);
	};

	useEffect(() => {
		setSkip(skip.concat(skipped));
	}, [skipped]);

	// Do not show section, if no items
	if (list !== null && list.length === 0 && noResult === null) return null;

	return (
		<div className={styles.default} ref={ref}>
			{inView && (
				<div className={noContainer ? '' : 'container'}>
					{list !== null && list.length > 0 && title && (
						<div className="row">
							<div className="col-12">
								<h2>{title} {link && <Link href={link}><a data-mobile-txt={t('btnReadMoreShort')}><span>{t('btnReadMore')}</span> <i className="icon arrow-right-primary" /></a></Link>}</h2>
							</div>
						</div>
					)}

					<div className="row relative">
						{list === null && (loading && loader) && <LoaderSpinner />}
						{list !== null && carousel && list.length > 0 && (<Carousel {...carouselProps} append={append}>{list.map(row => <ItemListBox key={row.real_estate_id} row={row} {...itemProps} />)}</Carousel>)}
						{list !== null && !carousel && list.length > 0 && list.map((row) => {
							if (row.real_estate_id && !skip.includes(row.real_estate_id)) {
								return (
									<div key={row.real_estate_id} className={colOpt}>
										<ItemListBox row={row} {...itemProps} addToListSkipped={addToListSkipped} />
									</div>
								);
							}

							return true;
						})}
						{list !== null && list.length === 0 && noResult && <NoResult {...noResult}>{noResult.text}</NoResult>}
					</div>
				</div>
			)}
		</div>
	);
});

ItemList.defaultProps = {
	title: null,
	link: null,
	noContainer: false,
	colClass: 'col-lg-3 col-12',
	filters: {},
	carousel: false,
	options: {},
	colOpt: 'col-lg-4 col-12',
	onQueryEnd: null,
	itemProps: {},
	append: null,
	skipped: [],
	noResult: null,
	page: 1,
	perPage: 20,
	orderBy: null,
	loader: false,
	name: 'items-list',
};

ItemList.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string,
	noContainer: PropTypes.bool,
	colClass: PropTypes.string,
	filters: PropTypes.shape({}),
	carousel: PropTypes.bool,
	options: PropTypes.shape({}),
	colOpt: PropTypes.string,
	onQueryEnd: PropTypes.func,
	itemProps: PropTypes.shape({}),
	append: PropTypes.shape({}),
	skipped: PropTypes.arrayOf(PropTypes.string),
	noResult: PropTypes.shape({}),
	page: PropTypes.number,
	perPage: PropTypes.number,
	orderBy: PropTypes.string,
	loader: PropTypes.bool,
	name: PropTypes.string
};

export default ItemList;
