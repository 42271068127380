import React from 'react';
import Link from 'next/link';
import { useInView } from 'react-intersection-observer';

import styles from './text-box-cta.module.scss';
import useLanguage from '../_hooks/useLanguage';
import useUser from '../_hooks/useUser';
import useRedirect from '../_hooks/useRedirect';

const TextBoxCta = () => {
	const { ref, inView } = useInView({
		threshold: 0,
		triggerOnce: true
	});

	const { t } = useLanguage();
	const { showCallout } = useUser();
	const { getUri } = useRedirect();

	return (
		<div className={styles.default} ref={ref}>
			{inView && (
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2>{t('textBoxCtaTitle1')}<strong>PRO!</strong></h2>
							<p>{t('textBoxCtaText1')}</p>
							<div className={styles.buttons}>
								<button type="button" onClick={() => showCallout()} className="btn primary big">{t('btnBecomePro')}</button>
								<Link href={getUri('pricelist')}><a className="btn plain white">{t('btnPricelist')}</a></Link>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TextBoxCta;
