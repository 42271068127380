import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './carousel.module.scss';
import useWindowDimensions from '../_hooks/useWindowDimensions';

let activeMutable = 0; // keyboard event compatibility

const Carousel = ({ children, marginWidth, options, noGutter, append, keyboardControl }) => {
	const [active, setActive] = useState(0);

	const [itemWidth, setItemWidth] = useState(null);
	const [canvasWidth, setCanvasWidth] = useState(0);
	const [isControles, setIsControles] = useState(true);
	const [boundaryClass, setBoundaryClass] = useState('leftEnd');
	const [opts, setOpts] = useState(options);
	const [childrenCnt, setChildrenCnt] = useState(0);

	// Handle remote control (ex. from parent)
	useEffect(() => {
		if (options.remoteActiveSlide !== undefined && options.remoteActiveSlide !== null) {
			setActive(options.remoteActiveSlide);
		}
	}, [options.remoteActiveSlide]);

	// Set correct items cnt
	const { width } = useWindowDimensions();

	useEffect(() => {
		if (options.responsive) {
			let lastBreakpoint = 0;

			Object.keys(options.responsive).map((breakpoint) => {
				if (width >= breakpoint) {
					lastBreakpoint = breakpoint;
				}
				return true;
			});

			setOpts({ options, ...options.responsive[lastBreakpoint] });
		}
	}, [width]);

	const canvas = useRef(null);
	const lastX = useRef(0);

	const touchStart = (e) => {
		lastX.current = e.touches[0].clientX;
	};

	const touchEnd = (e) => {
		const te = e.changedTouches[0].clientX;

		if (lastX.current > te + 5) {
			direction(+1);
		} else if (lastX.current < te - 5) {
			direction(-1);
		}
	};

	useEffect(() => {
		const cWidth = (canvas && canvas.current.getBoundingClientRect()) ? canvas.current.getBoundingClientRect().width : null

		if (cWidth && opts.items) {
			const mW = noGutter ? 0 : marginWidth;
			const iW = Math.round(cWidth / opts.items) - mW;

			// Calculate number of childrens
			let cL = children.length;

			if (append) {
				cL += Object.keys(append).length;
			}

			setChildrenCnt(cL);

			if (cL <= opts.items) {
				setIsControles(false);
			}

			setItemWidth(iW);
			setCanvasWidth((iW + mW) * cL);

			if (keyboardControl) {
				window.addEventListener('keydown', handleKeyDown);

				// cleanup this component
				return () => {
					window.removeEventListener('keydown', handleKeyDown);
				};
			}
		}

		return true;
	}, [opts]);

	const direction = (factor, isKeyDown = false) => {
		const next = (isKeyDown ? activeMutable : active) + factor;

		if (next > -1 && next <= (childrenCnt - opts.items)) {
			if (boundaryClass !== 'ready') {
				setBoundaryClass('ready');
			}

			activeMutable = next;
			setActive(next);
		}

		if (next < 1) {
			setBoundaryClass('leftEnd');
		}

		if (next > (childrenCnt - opts.items) - 1) {
			setBoundaryClass('rightEnd');
		}
	};

	const mW = noGutter ? 0 : marginWidth;
	const gutterWidth = noGutter ? 0 : mW / 2;

	// Keyboard control

	const handleKeyDown = (e) => {
		if ([37, 39].includes(e.keyCode)) { // arrow left & right
			direction(e.keyCode === 37 ? -1 : 1, true);
		}
	};

	return (
		<div className={`${styles[boundaryClass]} ${styles.default}`} ref={canvas} style={{ paddingLeft: `${gutterWidth}px`, paddingRight: `${gutterWidth}px` }} onTouchStart={touchStart} onTouchEnd={touchEnd}>
			{isControles && (
				<>
					<button type="button" className={`${styles.prev} ${styles.control}`} onClick={() => direction(-1)} aria-label="Nazaj"><i className="icon control-arrow-left" /></button>
					<button type="button" className={`${styles.next} ${styles.control}`} onClick={() => direction(+1)} aria-label="Naprej"><i className="icon control-arrow-right" /></button>
				</>
			)}

			<div className={styles.canvas} style={{ left: itemWidth ? -((itemWidth + mW) * active) : 0, width: `${canvasWidth}px` }}>
				{React.Children.map(children, (child, i) => {
					if (child) {
						return <div style={{ width: `${itemWidth}px`, marginRight: `${mW}px` }}>{child}</div>;
					}

					return false;
				})}

				{append && Object.keys(append).map(k => <div key={k}>{append[k]}</div>)}
			</div>
		</div>
	);
};

Carousel.defaultProps = {
	marginWidth: 30,
	options: {
		remoteActiveSlide: null,
		responsive: {
			0: { items: 1 },
			600: { items: 2 },
			800: { items: 3 },
			1350: { items: 4 }
		}
	},
	noGutter: false,
	append: null,
	keyboardControl: false
};

Carousel.propTypes = {
	children: PropTypes.node.isRequired,
	marginWidth: PropTypes.number,
	options: PropTypes.shape({}),
	noGutter: PropTypes.bool,
	append: PropTypes.shape({}),
	keyboardControl: PropTypes.bool
};

export default Carousel;
